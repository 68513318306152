import React from "react"
import { graphql } from "gatsby"
import Site from "../components/Site"
import TalksPlaylist from "../components/TalksPlaylist"
import TalksRecent from "../components/TalksRecent"
import { formatLink } from '../helpers'
import "../css/talks.scss"

const Series = ({data}) => {
  const theSeries         = data.datoCmsSeries
  const allTalks          = [...data.allDatoCmsTalk.edges].map(el => el.node)
  const allFromSameSeries = [...allTalks].filter(aTalk => aTalk.series.slug === theSeries.slug)

  return (
    <Site
      title      = {`${theSeries.title} Series | Talks`}
      className  = "hcc-talks hcc-talks-series"
      themeColor = "#141414"
    >
      <article>
        <TalksPlaylist
          data  = {allFromSameSeries}
          title = {<React.Fragment><span>All Talks from</span> <strong>{theSeries.title}</strong></React.Fragment>}
        />
        <TalksRecent
          data   = {allTalks}
          title  = "More Talks"
          offset = {0}
          count  = {10}
          more   = {formatLink('', 't')}
        />
      </article>
    </Site>
  )
}

export default Series

export const query = graphql`
  query SeriesQuerySingle($slug : String!) {
    datoCmsSeries(slug: {eq : $slug}) {
      title
      slug
      image {
        url
        width
        height
      }
    }
    allDatoCmsTalk(
      sort : {fields : date, order : DESC}
    ) {
      edges {
        node {
          ...TalkFragment
        }
      }
    }
  }
`
